export const getDecodedDataFromLocalStorage = (key: string): any | null => {
  try {
    const encodedData = localStorage.getItem(key)
    if (encodedData) {
      const decodedData = JSON.parse(window.atob(encodedData))
      return decodedData
    }
  } catch (error) {
    console.error(
      `Failed to retrieve or parse data for key "${key}" from localStorage:`,
      error
    )
  }
  return null
}

export const saveEncodedDataToLocalStorage = (
  key: string,
  newData: Record<string, string | null>
) => {
  try {
    if (typeof window === 'undefined') return // Prevent execution on the server

    const existingData = localStorage.getItem(key)
    let mergedData = newData

    if (existingData) {
      try {
        const decodedData = JSON.parse(window.atob(existingData)) // Decode existing base64 data
        if (typeof decodedData === 'object' && decodedData !== null) {
          mergedData = { ...decodedData, ...newData } // Merge with new data
        }
      } catch (parseError) {
        console.error(
          'Failed to decode existing localStorage data:',
          parseError
        )
      }
    }

    const encodedData = window.btoa(JSON.stringify(mergedData)) // Encode merged data
    localStorage.setItem(key, encodedData)
  } catch (error) {
    console.error('Error saving encoded data to localStorage:', error)
  }
}

export const convertKeysToSnakeCase = (
  trackingParams: Record<string, any> | null | undefined
): Record<string, any> => {
  if (!trackingParams || typeof trackingParams !== 'object') {
    console.error('trackingParams must be a non-null object')
    return {}
  }
  const newObject: Record<string, any> = {}
  for (const [key, value] of Object.entries(trackingParams)) {
    if (['productType', 'partnerCode', 'contentId'].includes(key)) {
      newObject[key] = value
    } else {
      const snakeCaseKey = toSnakeCase(key, key.startsWith('_'))
      if (Array.isArray(value)) {
        newObject[snakeCaseKey] = value.map((item) =>
          typeof item === 'string'
            ? toSnakeCase(item, item.startsWith('_'))
            : item
        )
      } else {
        newObject[snakeCaseKey] = value
      }
    }
  }

  return newObject
}

// Utility function to convert a string to snake_case
const toSnakeCase = (str: string, retainLeadingUnderscore: boolean): string => {
  const converted = str
    .replace(/([A-Z])/g, (letter) => `_${letter.toLowerCase()}`) // Convert uppercase letters to snake_case
    .replace(/__+/g, '_') // Remove duplicate underscores

  return retainLeadingUnderscore
    ? `_${converted.replace(/^_/, '')}`
    : converted.replace(/^_/, '')
}
